import React, { Fragment } from 'react';

import { selectorScrollTo } from '../../../../helpers/scrollTo';
import TreatmentCache from '../../../../types/api/products/TreatmentCache';
import formatPrice from '../../../../helpers/formatPrice';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import StarRating from '../../../_ui/_blocks/StarRating/StarRating';
import Tag from '../../../_ui/_blocks/Tag/Tag';
import * as styles from './TreatmentSelectorHeader.module.css';

interface Props {
    title: string;
    review: TreatmentCache['selectedReview'];
    fromPrice: number;
    gppcFromPrice?: string;
    scrollToSelector?: string; // The selector the reviews click will scroll to.
    headingTag?: 'h3' | 'h1';
    percentageSaving?: number;
}

interface ReviewNoLinkProps {
    children: React.ReactNode;
}

interface ReviewWithLinkProps extends ReviewNoLinkProps {
    scrollToSelector: string;
}

/**
 * Reviews component that is not wrapped in a scroll link.
 */
const ReviewNoLink = ({ children }: ReviewNoLinkProps) => <div className="flex items-center gap-050 h-[1.3125rem]">{children}</div>;

/**
 * Reviews component that is wrapped in a scroll link.
 */
const ReviewWithLink = ({ scrollToSelector, children }: ReviewWithLinkProps) => {
    const onReviewsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        selectorScrollTo(scrollToSelector);
    };

    return (
        <a
            onClick={onReviewsClick}
            href="#treatment-reviews"
            className="flex items-center gap-050 h-[1.3125rem] underline"
            title="View reviews"
        >
            {children}
        </a>
    );
};

/**
 * Header component for the treatment selector.
 */
const Header = ({ title, review, fromPrice, gppcFromPrice, scrollToSelector, percentageSaving, headingTag = 'h3' }: Props) => {
    const { numRatings, averageRating } = review;

    // If there is a scrollToSelector, wrap the reviews in a scroll link.
    const ReviewsWrapper = scrollToSelector ? ReviewWithLink : ReviewNoLink;
    return (
        <div>
            <div className="flex flex-row flex-wrap gap-100 justify-between items-center">
                <Typography as={headingTag} typeset="title" lineHeight="400" color="accent" className="w-full md:w-auto">
                    {title}
                </Typography>
                {fromPrice || gppcFromPrice ? (
                    <Typography as="div" typeset="heading" lineHeight="none" className={styles.priceLabel}>
                        {gppcFromPrice ? (
                            gppcFromPrice
                        ) : (
                            <Fragment>
                                <Typography size="080">from</Typography> {formatPrice(fromPrice)}
                            </Fragment>
                        )}
                    </Typography>
                ) : null}
            </div>
            {review.numRatings || percentageSaving ? (
                <div className="flex pt-100 gap-100 justify-between items-center">
                    {review.numRatings ? (
                        <ReviewsWrapper scrollToSelector={scrollToSelector || ''}>
                            <StarRating rating={averageRating} />
                            <Typography size="080" lineHeight="none">
                                {numRatings} reviews
                            </Typography>
                        </ReviewsWrapper>
                    ) : null}
                    {percentageSaving ? (
                        <Tag size="large" color="error" className="flex-shrink-0 !normal-case">
                            Save up to {percentageSaving}%
                        </Tag>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default Header;
