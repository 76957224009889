module.exports = [{
      plugin: require('../plugins/general-plugin-chunk-reload/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"the-independent-pharmacy","short_name":"the-independent-pharmacy","start_url":"/","background_color":"{config.colors.primary.navy}","theme_color":"{config.colors.primary.navy}","display":"minimal-ui","icon":"src/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b4f375e56388b048b7f1c9265035561"},
    },{
      plugin: require('../plugins/tracking-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T24W5Z8","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../plugins/tracking-plugin-sentry/gatsby-browser.ts'),
      options: {"plugins":[],"enabled":true,"dsn":"https://d0594deb2c9fc78091f31398e0c56641@o4505720336613376.ingest.sentry.io/4505722522763264","environment":"staging","sampleRate":"1","allowUrls":[{},{},"https://staging.www.theindependentpharmacy.co.uk/",null,"https://www.googletagmanager.com/","https://cdn.checkout.com/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
