import React, { HTMLAttributes } from 'react';
import clx from 'classnames';

export enum CardVariants {
    regular = '',
    bordered = 'card-bordered',
    borderless = 'card-borderless',
}

export enum CardSizes {
    small = 'card-size-small',
    medium = 'card-size-medium',
    large = 'card-size-large',
    custom = '',
}

interface Props extends HTMLAttributes<HTMLElement> {
    as?: 'div' | 'article' | 'form' | 'li';
    size?: 'small' | 'medium' | 'large' | 'custom';
    children: React.ReactNode;
    variant?: keyof typeof CardVariants;
    className?: string;
}

const Card = ({ as: Element = 'article', size = 'medium', variant = 'regular', children, className, ...props }: Props) => {
    const classNames = clx('card', CardSizes[size], CardVariants[variant], className);
    return (
        <Element {...props} className={classNames}>
            {children}
        </Element>
    );
};

export default Card;
