import React from 'react';

const IconChevronLeft = () => (
    <path
        fillRule="evenodd"
        d="M37.4453 0.617558C38.2088 1.41577 38.1807 2.68178 37.3824 3.44529L15.8934 24L37.3824 44.5547C38.1807 45.3182 38.2088 46.5842 37.4453 47.3825C36.6818 48.1807 35.4158 48.2088 34.6176 47.4453L11.6176 25.4453C11.2231 25.068 11 24.5459 11 24C11 23.4542 11.2231 22.932 11.6176 22.5547L34.6176 0.554719C35.4158 -0.208784 36.6818 -0.18065 37.4453 0.617558Z"
    />
);

export default IconChevronLeft;
