import React from 'react';
import { Link } from 'gatsby';
import { genericHashLink } from 'react-router-hash-link';

import { useAuthContext } from '../../../../data/context/authContext';

import Button from '../../../_ui/_blocks/Buttons/Button/Button';

import * as styles from '../../DrilldownNav/DrilldownNav.module.css';

interface Props {
    isOpen: boolean;
}

const SidebarAccountMenu = ({ isOpen }: Props) => {
    // @ts-ignore
    const ScrollLink = genericHashLink(Link);

    const { logout } = useAuthContext();

    const handleLogout = () => logout();

    return (
        <div className={`${styles.drillDownFlyout} ${isOpen ? styles.drillDownFlyoutShow : ''}`}>
            <ul className={styles.list}>
                <li className="m-050">
                    <Link to="/account" className={styles.accountTitle} activeClassName={styles.linkActive} title="Dashboard">
                        Dashboard
                    </Link>
                </li>
                <li>
                    <Link to="/account/orders" className={styles.link} activeClassName={styles.linkActive} title="Orders" partiallyActive>
                        Orders
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/orders#order-history"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                partiallyActive
                                title="Order history"
                            >
                                View your order history
                            </ScrollLink>
                        </li>
                        <li>
                            <Link
                                to="/account/orders/treatment-reorders"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                partiallyActive
                                title="Treatment reorders"
                            >
                                Reorder a treatment
                            </Link>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/orders#saved-baskets"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                partiallyActive
                                title="Saved baskets"
                            >
                                Load a saved basket
                            </ScrollLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        to="/account/delivery-addresses"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Billing & delivery"
                        partiallyActive
                    >
                        Billing & delivery
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            <Link
                                to="/account/delivery-addresses"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Delivery addresses"
                            >
                                Delivery addresses
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/account/saved-payment-methods"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Payment methods"
                            >
                                Payment methods
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        to="/account/personal-medical-record"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Personal Medical Record"
                        partiallyActive
                    >
                        Personal Medical Record
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#personal-details"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Personal details"
                            >
                                Personal details
                            </ScrollLink>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#consultations"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Consultations"
                            >
                                Consultations
                            </ScrollLink>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#test-results"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Test results"
                            >
                                Test results
                            </ScrollLink>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#prescriptions"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Prescriptions"
                            >
                                Prescription Requests
                            </ScrollLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        to="/account/settings"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Settings"
                        partiallyActive
                    >
                        Edit Details
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            <Link
                                to="/account/settings"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Personal details"
                            >
                                Personal details
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/account/settings/weight-and-height"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Weight &amp; height"
                            >
                                Weight &amp; height
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/account/settings/password"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Update password"
                            >
                                Update password
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
            <div className={styles.signIn}>
                <Button variant="secondary" title="Sign out of your account" onClick={handleLogout}>
                    Sign out
                </Button>
            </div>
        </div>
    );
};

export default SidebarAccountMenu;
