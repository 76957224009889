import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import clx from 'classnames';

import { useAuthContext } from '../../../../data/context/authContext';
import { useSidebarNavigationValueContext, useSidebarNavigationUpdaterContext } from '../../../../data/context/sidebarNavigationContext';
import { useAccountNavigationContext } from '../../../../data/context/accountContext/accountNavigationContext';

import IconChevronRight from '../../../_ui/_blocks/Icons/Navigation/IconChevronRight';
import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import CraftMenuLink, { MenuNode } from '../../CraftMenuLink/CraftMenuLink';
import ButtonLink from '../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';

import * as styles from './SidebarNavigation.module.css';
import SidebarFlyout from '../../../_ui/SidebarFlyout/SidebarFlyout';
import DrilldownNav from '../../DrilldownNav/DrilldownNav';
import { CRAFT_URL } from '../../../../config/config';
import SidebarAccountMenu from '../../AccountNavigation/SidebarAccountMenu/SidebarAccountMenu';

/**
 * Mobile navigation
 */
const SidebarNavigation = () => {
    const [selectedSubNav, setSelectedSubNav] = useState<string | null>(null);

    const { isLoggedIn, logout } = useAuthContext();

    const sidebarNavIsOpen = useSidebarNavigationValueContext();
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();
    const { accountNavIsOpen, setAccountNavIsOpen } = useAccountNavigationContext();

    const data = useStaticQuery(graphql`
        {
            craftcms {
                links: nodes(navHandle: "mainNavigation", level: 1) {
                    ...mainNavigation
                }
            }

            allCondition {
                nodes {
                    treatmentCount
                    computed {
                        relatedTreatmentCount
                    }
                    entry {
                        slug
                    }
                    title
                }
            }
        }
    `);

    const { links } = data.craftcms;

    const { allCondition } = data;

    const conditions = allCondition.nodes.map((n: any) => ({
        ...n.entry,
        treatmentCount: n.treatmentCount,
        relatedTreatmentCount: n.computed.relatedTreatmentCount,
    })); // remap nodes.entry to just entry

    const handleLogout = () => logout();
    const handleAccountClick = () => {
        setAccountNavIsOpen(true);
    };

    const handleItemClick = (id: string) => {
        setSelectedSubNav(id);
    };

    const handleBackToMenu = () => {
        setSidebarNavIsOpen(true);
        setAccountNavIsOpen(false);
        setSelectedSubNav(null);
    };

    const handleCloseNavigation = () => {
        setSidebarNavIsOpen(false);
        setAccountNavIsOpen(false);
        setSelectedSubNav(null);
    };

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('routeUpdate', handleCloseNavigation);
        return () => window.removeEventListener('routeUpdate', handleCloseNavigation); // eslint-disable-line consistent-return

        // handleCloseNavigation doesn't change, so no need to add it to the deps array
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const navClassName = clx(styles.nav, {
        [styles.navBottomPadding]: !isLoggedIn,
    });

    return (
        <SidebarFlyout
            isOpen={sidebarNavIsOpen}
            title="Menu"
            onClose={handleCloseNavigation}
            className={styles.flyout}
            onBack={selectedSubNav || accountNavIsOpen ? handleBackToMenu : undefined}
        >
            <nav className={navClassName}>
                {isLoggedIn ? (
                    <div className={styles.signIn}>
                        <Button title="Sign out of your account" onClick={handleLogout}>
                            Sign out
                        </Button>
                    </div>
                ) : (
                    <div className={styles.signIn}>
                        <ButtonLink to="/login" title="Signin to your account" variant="primary">
                            Sign In
                        </ButtonLink>
                    </div>
                )}
                <ul className={styles.list}>
                    <li>
                        <Link to="/" className={styles.link} title="Home" activeClassName={styles.linkActive}>
                            Home
                        </Link>
                    </li>
                    {isLoggedIn ? (
                        <li>
                            <button type="button" className={styles.link} onClick={handleAccountClick}>
                                Your account
                                <BaseIcon size="xxsmall" className="ml-auto" color="accent">
                                    <IconChevronRight />
                                </BaseIcon>
                            </button>
                            {isLoggedIn ? <SidebarAccountMenu isOpen={accountNavIsOpen} /> : null}
                        </li>
                    ) : null}
                    {links.map((link: MenuNode) => (
                        <li key={link.id}>
                            {link.children.length ? (
                                <button type="button" className={styles.link} title={link.title} onClick={() => handleItemClick(link.id)}>
                                    {link.title}
                                    <BaseIcon size="xxsmall" className="ml-auto" color="accent">
                                        <IconChevronRight />
                                    </BaseIcon>
                                </button>
                            ) : (
                                <CraftMenuLink menuNode={link} className={styles.link} title={link.title}>
                                    {link.title}
                                </CraftMenuLink>
                            )}

                            {link.children.length ? (
                                <DrilldownNav
                                    title={link.title}
                                    navItems={link.children as MenuNode[]}
                                    isOpen={selectedSubNav === link.id}
                                    conditions={conditions}
                                    itemUrl={link.url?.replace(CRAFT_URL, '').replace('https://tip-cms.builtbyelement.io', '')}
                                />
                            ) : null}
                        </li>
                    ))}
                </ul>
            </nav>
        </SidebarFlyout>
    );
};

export default SidebarNavigation;
