import React, { useRef, useEffect } from 'react';
import clx from 'classnames';

import { BorderRadiusMap } from '../styles/types';

import Label from '../Label/Label';

import * as styles from '../styles/Inputs.module.css';

export const telPattern = `^[0-9-+\\s()]*$`;

type LabelProps = React.ComponentProps<typeof Label>;

interface Props extends React.AllHTMLAttributes<HTMLInputElement>, LabelProps {
    id: string;
    label?: string;
    className?: string;
    wrapperTag?: 'div';
    isValid?: boolean;
    isDirty?: boolean;
    type?: 'number' | 'tel';
    borderRadius?: keyof typeof BorderRadiusMap;
    dataLpignore?: boolean;
}

const NumberInput = ({
    id,
    label,
    description,
    className,
    wrapperTag,
    isDirty,
    isValid,
    borderRadius = 'default',
    type = 'number',
    optional,
    ...rest
}: Props) => {
    // We're running into an issue with the number input where the user is accidentally changing the input value by scrolling up or down so here we're disabling scroll wheel up or down whilst the user is hovered over the number input only issue is on track pad it completely disables mouse going up or down
    const textFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            e.preventDefault();
        };
        const currentRef = textFieldRef.current;
        currentRef?.addEventListener('wheel', handleWheel);
        return () => currentRef?.removeEventListener('wheel', handleWheel);
    }, []);

    let inputOnlyClassName = clx(styles.field, BorderRadiusMap[borderRadius], {
        [styles.fieldValid]: isDirty && isValid,
        [styles.fieldInvalid]: isDirty && !isValid,
    });

    if (!label) {
        inputOnlyClassName = clx(inputOnlyClassName, className);
        return (
            <input {...rest} id={id} className={inputOnlyClassName} type={type} inputMode="numeric" pattern="[0-9]*" ref={textFieldRef} />
        );
    }

    const Wrapper = wrapperTag || 'div';
    const wrapperClassNames = clx(className, styles.labelWrap);

    return (
        <Wrapper className={wrapperClassNames}>
            <Label id={id} label={label} description={description} optional={optional} />
            <input {...rest} id={id} className={inputOnlyClassName} type={type} inputMode="numeric" pattern="[0-9]*" ref={textFieldRef} />
        </Wrapper>
    );
};

export default NumberInput;
