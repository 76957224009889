import React from 'react';
import get from 'lodash.get';
import dayjs from 'dayjs';
import { graphql, useStaticQuery } from 'gatsby';

import Typography from '@components/_ui/_blocks/Typography/Typography';
import Avatar from '@components/Avatar/Avatar';

import * as styles from '../../Footer.module.css';

interface ProfileImage {
    url: string;
}

export interface ResponsiblePharmacist {
    firstName: string;
    lastName: string;
    profileUrlPageSlug: string;
    profileImage: ProfileImage[];
}

interface Data {
    craftcms: {
        sitewide: {
            responsiblePharmacist: ResponsiblePharmacist[];
        };
    };
}

type ReturnType = JSX.Element | null;

/**
 * Displays the responsible Pharmacist Found in the footer.
 * @param props
 * @returns {JSX.Element}
 */
const ResponsiblePharmacist = (): ReturnType => {
    const data: Data = useStaticQuery(graphql`
        {
            craftcms {
                sitewide: globalSet(handle: "sitewideContent") {
                    ... on CraftCMS_sitewideContent_GlobalSet {
                        responsiblePharmacist {
                            ... on CraftCMS_User {
                                firstName
                                lastName
                                profileUrlPageSlug
                                profileImage(withTransforms: ["avatar"]) {
                                    url(transform: "avatar")
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    // Gets the responsible pharmacist for the footer.
    const responsiblePharmacist = get(data, 'craftcms.sitewide.responsiblePharmacist[0]');

    // Get the current date in format.
    const today = dayjs().local().format('YYYY-MM-DD');

    // If it's not the weekend and the time is between 08:30 and 14:00.
    const isDisplayTimeSlot = dayjs().local().isoWeekday() <= 5 && dayjs().local().isBetween(`${today} 08:30:00`, `${today} 17:00:00`);

    // If it's the wrong time to display the Responsible Pharmacist or there is not one set, don't display.
    if (!isDisplayTimeSlot || !responsiblePharmacist) {
        return null;
    }

    const ResponsiblePharmacistName = `${responsiblePharmacist.firstName} ${responsiblePharmacist.lastName}`;
    const ResponsiblePharmacistImage = get(responsiblePharmacist, 'profileImage[0].url') || '/images/profile.svg';

    return (
        <div className={styles.footer_pharmacist}>
            <Typography typeset="heading" size="090">
                Today's Responsible Pharmacist
            </Typography>
            <a
                href={`/about/meet-the-team/${responsiblePharmacist.profileUrlPageSlug}`}
                title={ResponsiblePharmacistName}
                className="flex items-center typeset-paragraph type-090 gap-050 w-max"
                data-visual-test="transparent"
            >
                <Avatar src={ResponsiblePharmacistImage} alt="ResponsiblePharmacistName" size="small" shape="pill" />
                {ResponsiblePharmacistName}
            </a>
        </div>
    );
};

export default ResponsiblePharmacist;
