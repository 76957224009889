// extracted by mini-css-extract-plugin
export var backButton = "backButton_P8-jyHkwOw";
export var close = "close_4Z3XPwgZ73";
export var dialog = "dialog_cKj0TTVhSh";
export var fadeIn = "fadeIn_w9Ngm306m4";
export var fadeOut = "fadeOut_UohNprU-4e";
export var flyout = "flyout_7QXYLnxaM+";
export var flyoutRight = "flyoutRight_NJEsJ4vXAA";
export var header = "header_ZrONsMRnBj";
export var inner = "inner_u58lbc3PZu";
export var overlay = "overlay_yPLFMV0uOX";
export var title = "title_-Z3ILJLS05";