import React, { Fragment, useMemo } from 'react';
import dayjs from 'dayjs';

import { useBasketContext } from '../../../../data/context/basketContext/basketContext';
import formatPrice from '../../../../helpers/formatPrice';
import useDeliveryCutOffCountDown from './hooks/useDeliveryCutOffCountDown/useDeliveryCutOffCountDown';
import useNextDelivery from './hooks/useNextDelivery/useNextDelivery';

import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import IconTruck from '../../../_ui/_blocks/Icons/IconTruck';
import LoadingIcon from '../../../_ui/_blocks/Icons/LoadingIcon/LoadingIcon';
import IconCalender from '../../../_ui/_blocks/Icons/IconCalender';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import { isTomorrow } from '../../../../helpers/dateFunctions/dateFunctions';

const AMOUNT_FOR_FREE_DELIVERY = 4000;

interface Props {
    isError?: boolean;
    inStock?: boolean;
}

/**
 * List of incentives on why a treatment should be bought. Requests delivery information to display to the
 * user from the API.
 */
const Incentives = ({ isError, inStock }: Props) => {
    // @ts-expect-error
    const { basket } = useBasketContext();
    const { nextDeliveryDate, cutOffTime, isLoading, error, refetch } = useNextDelivery();

    const deliveryDate = useMemo(() => {
        if (!nextDeliveryDate) {
            return null;
        }

        return isTomorrow(nextDeliveryDate) ? 'Tomorrow' : dayjs(nextDeliveryDate, 'YYYY-MM-DD').format('dddd MMM DD');
    }, [nextDeliveryDate]);

    const timeRemaining = useDeliveryCutOffCountDown({ cutOffTime, onCountDownEnd: refetch });

    const amountRemainingForFreeDelivery = AMOUNT_FOR_FREE_DELIVERY - (basket?.total || 0);
    const qualifiesForFreeDelivery = amountRemainingForFreeDelivery <= 0;

    const freeDeliveryHeading = qualifiesForFreeDelivery ? 'Includes free standard delivery' : 'Free standard delivery on orders over £40';

    const freeDeliveryDescription = qualifiesForFreeDelivery
        ? 'Your order qualifies for free delivery!'
        : `Spend ${formatPrice(amountRemainingForFreeDelivery)} more for free delivery`;

    return (
        <ul className="space-y-100">
            <li className="flex gap-050 items-center">
                <BaseIcon className="flex-shrink-0 flex-grow-0" color="tertiary-500" size="medium">
                    <IconTruck />
                </BaseIcon>
                <div>
                    <Typography as="div" typeset="heading" size="080">
                        {freeDeliveryHeading}
                    </Typography>
                    <Typography as="div" color="quiet" size="080">
                        {freeDeliveryDescription}
                    </Typography>
                </div>
            </li>
            {!error && !isError && inStock ? (
                <li className="flex gap-050 items-center">
                    <BaseIcon className="flex-shrink-0 flex-grow-0" color="tertiary-500" size="medium">
                        <IconCalender />
                    </BaseIcon>
                    <div>
                        {isLoading && !nextDeliveryDate ? (
                            <LoadingIcon />
                        ) : (
                            <Fragment>
                                {nextDeliveryDate && (
                                    <Typography as="div" typeset="heading" size="080">
                                        {`Fastest delivery: ${deliveryDate}`}
                                    </Typography>
                                )}
                                {timeRemaining && (
                                    <Typography as="div" color="quiet" size="080">
                                        {`Order within ${timeRemaining}`}
                                    </Typography>
                                )}
                            </Fragment>
                        )}
                    </div>
                </li>
            ) : null}
        </ul>
    );
};

export default Incentives;
