import React, { Fragment } from 'react';
import get from 'lodash.get';
import * as styles from './DrilldownNav.module.css';
import Typography from '../../_ui/_blocks/Typography/Typography';
import CraftMenuLink, { MenuNode } from '../CraftMenuLink/CraftMenuLink';
import ButtonLink from '../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

interface Props {
    title: string;
    navItems: MenuNode[] | null;
    isOpen: boolean;
    conditions: {
        id: string;
        treatmentCount: number;
        slug?: string | undefined;
    }[];
    itemUrl?: string;
}

const DrilldownNav = ({ navItems, title, isOpen, conditions = [], itemUrl }: Props) => (
    <div className={`${styles.drillDownFlyout} ${isOpen ? styles.drillDownFlyoutShow : ''}`}>
        <ul className={title === 'Treatments' ? styles.list : styles.listBorderless}>
            <li className={title === 'Treatments' ? null : styles.linkTitleBordered}>
                <Typography
                    as="h3"
                    typeset="title"
                    size="200"
                    weight="500"
                    className={title === 'Treatments' ? 'p-050' : 'px-050'}
                    color="accent"
                >
                    {title === 'About' ? title : `Popular ${title}`}
                </Typography>
            </li>

            {navItems &&
                navItems.map((item) => {
                    const condition = conditions.find((c) => c.slug === item.element?.slug);

                    if (item.url!.includes('brand')) {
                        Object.assign(item, { url: item.url!.replace('brand/', 'brands/') });
                    }

                    return (
                        <Fragment key={item.title}>
                            <li key={item.title} className={styles.drilldownItem}>
                                <CraftMenuLink menuNode={item} className={styles.link} activeClassName={styles.linkActive} title={title}>
                                    {item.title}
                                    {condition && (
                                        <Typography className="text-content-quiet">
                                            ({get(condition, 'relatedTreatmentCount', 0)})
                                        </Typography>
                                    )}
                                </CraftMenuLink>
                                {item.children.length ? (
                                    <Fragment>
                                        <ul className={styles.submenu}>
                                            {item.children.map((child) => (
                                                <li key={child.title}>
                                                    <CraftMenuLink
                                                        menuNode={child}
                                                        className={styles.sublink}
                                                        activeClassName={styles.sublinkActive}
                                                        title={child.title}
                                                    >
                                                        {child.title}
                                                    </CraftMenuLink>
                                                </li>
                                            ))}
                                        </ul>
                                        <CraftMenuLink menuNode={item} title="View All">
                                            <Typography color="highlight" className="underline hover:no-underline px-050 leading-800">
                                                View all
                                            </Typography>
                                        </CraftMenuLink>
                                    </Fragment>
                                ) : null}
                            </li>
                        </Fragment>
                    );
                })}
        </ul>
        {title !== 'About' ? (
            <div className={styles.signIn}>
                <ButtonLink variant="secondary" title={`View all ${title}`} to={itemUrl || ''}>
                    View all {title}
                </ButtonLink>
            </div>
        ) : null}
    </div>
);

export default DrilldownNav;
